<template>
  <div>
    <div class="t">
      <span>{{ title }}</span>
      <span><slot name="rightBtn"></slot></span>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
  },
};
</script>
<style scoped>
.t {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.t span:nth-child(1) {
  display: inline-block;
  color: #333333;
  font-size: 28px;
  position: relative;
  padding-left: 14px;
  height: 40px;
  line-height: 40px;
  font-weight: 600;
}
.t span:nth-child(1):after {
  content: "";
  width: 4px;
  height: 24px;
  background: red;
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}
</style>
