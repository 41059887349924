<template>
  <div
    class="order-container"
    :style="{ background: 'url(' + bg + ')', backgroundSize: '100% 100%' }"
  >
    <div class="t">{{ title }}</div>
    <div class="play" @click="play">
      <img src="../../assets/home/4.png" />
      <img src="../../assets/home/5.png" />
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
        @click="$router.push(`/singleDetail/${item.id}`)"
      >
        <div>
          <span>{{ index + 1 }}</span
          >{{ item.name }}
        </div>
        <div>{{ item.singer }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: Array,
    title: String,
    bg: String,
  },
  methods: {
    play() {
      this.$store.commit("ADD_PLAYLIST", this.list);
    },
  },
};
</script>
<style scoped lang="scss">
.order-container {
  width: 489px;
  height: 1150px;
  //background: #000;
  background-size: 100%;
}
.t {
  text-align: center;
  font-size: 40px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 40px 0 19px;
}
.play {
  width: 50px;
  height: 50px;
  margin: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 100%;
  }
  img:nth-child(1) {
    display: block;
  }
  img:nth-child(2) {
    display: none;
  }
  &:hover {
    img:nth-child(2) {
      display: block;
    }
    img:nth-child(1) {
      display: none;
    }
  }
}
.list {
  .item {
    margin: 20px 0 1px;
    font-size: 18px;
    padding: 7px 0 7px 43px;
    cursor: pointer;
    //&:hover{
    //  background: #F2F2F2aa;
    //}
    > div:nth-child(1) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #ffffff;
      > span {
        display: inline-block;
        width: 20px;
      }
    }
    > div:nth-child(2) {
      color: rgba(255, 255, 255, 0.61);
      padding-left: 20px;
      margin-top: 12px;
    }
  }
}
</style>
