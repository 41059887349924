<template>
  <div>
    <nav style="padding-left: 221px">
      <span
        :class="{ 'btn-current': currentIndex === index }"
        class="btn"
        v-for="(item, index) in navList"
        :key="index"
        @click="tap(item.type)"
      >
        {{ item.name }}
      </span>
    </nav>
    <main>
      <home-recommend v-if="currentIndex === 0"></home-recommend>
      <home-ranking v-if="currentIndex === 1"></home-ranking>
      <home-single v-if="currentIndex === 2"></home-single>
      <home-songs v-if="currentIndex === 3"></home-songs>
    </main>
    <!--    <home-swiper></home-swiper>-->
  </div>
</template>
<script>
import homeRecommend from "@/components/home/home-recommend.vue";
import homeRanking from "@/components/home/home-ranking";
import homeSingle from "@/components/home/home-single";
import homeSongs from "@/components/home/home-songs";
export default {
  data() {
    return {
      currentIndex: 0,
      navList: [
        {
          name: "推荐",
          type: 1,
        },
        {
          name: "排行",
          type: 2,
        },
        {
          name: "歌曲",
          type: 3,
        },
        {
          name: "DJ歌单",
          type: 4,
        },
      ],
    };
  },
  components: {
    homeRecommend,
    homeRanking,
    homeSingle,
    homeSongs,
  },
  watch: {
    "$route.query.type": {
      handler() {
        this.setCurrentIndex();
      },
    },
  },
  methods: {
    tap(type) {
      this.$router.push(`/home?type=${type}`);
    },
    setCurrentIndex() {
      let index = this.navList.findIndex(
        (item) => item.type == this.$route.query.type
      );
      this.currentIndex = index === -1 ? 0 : index;
    },
  },
  created() {
    this.setCurrentIndex();
  },
};
</script>
<style lang="scss" scoped>
nav {
  height: 68px;
  display: flex;
  align-items: center;
}
.btn {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 20px;
  color: #333333;
  font-size: 13px;
  background: #f8f8f8;
  border-radius: 15px;
  cursor: pointer;
  margin-right: 40px;
}
.btn:hover {
  background: #c20c0c !important;
  color: #ffffff !important;
}
.btn-current {
  background: #c20c0c !important;
  color: #ffffff !important;
}
</style>
