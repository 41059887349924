<template>
  <div>
    <div class="t">
      最新
      <span @click="reNewList">
        <img src="../../../assets/home/2.png" />
        <img src="../../../assets/home/1.png" />
        换一批
      </span>
    </div>
    <div class="row">
      <com-synopsis-big
        :ref="`newList${index}`"
        v-for="(item, index) in newList"
        class="list-anima"
        :key="index + newKey"
        :data="item"
        @to="$router.push(`singleDetail/${item.id}`)"
      ></com-synopsis-big>
    </div>

    <div class="hr"></div>

    <div class="t">
      最热
      <span @click="reHotList">
        <img src="../../../assets/home/2.png" />
        <img src="../../../assets/home/1.png" />
        换一批
      </span>
    </div>
    <!--    @to="$router.push(`songsDetail/${item.id}?name=${item.name}`)"-->
    <div class="row">
      <com-synopsis-big
        v-for="(item, index) in hotList"
        :key="index + hotKey"
        :data="item"
        class="list-anima"
        @to="$router.push(`singleDetail/${item.id}`)"
        :icon="require('../../../assets/home/7.png')"
      ></com-synopsis-big>
    </div>
  </div>
</template>
<script>
import comSynopsisBig from "@/components/com/com-synopsis-big";
import { randomBackPart } from "@/util/util";

export default {
  components: {
    comSynopsisBig,
  },
  data() {
    return {
      newList: [],
      newListed: [],
      hotList: [],
      newListAll: [],
      hotListAll: [],
      hotKey: 0,
      newKey: 0,
    };
  },
  methods: {
    reNewList() {
      // this.newListed = this.newList
      this.newKey++;
      this.newList = randomBackPart(this.newListAll, 5);
    },
    reHotList() {
      this.hotKey++;
      this.hotList = randomBackPart(this.hotListAll, 5);
    },
  },
  watch: {
    // newList(){
    //   let t = 1000;
    //   let i = 0
    //   this.newListed.forEach(item=>{
    //     setTimeout(()=>{
    //       this.$set(item,'anima',true)
    //     },t*i)
    //     i++
    //   })
    //   this.newList.forEach(item=>{
    //     setTimeout(()=>{
    //       this.$set(item,'anima',true)
    //     },t*i)
    //     i++
    //   })
    // }
  },
  mounted() {
    this.$api
      .singleList({ size: 20, current: 1, shelves: 1 })
      .then((resolve) => {
        for (let i = 0; i < resolve.data.records.length; i++) {
          resolve.data.records[i].play_mun = resolve.data.records[i].times;
          resolve.data.records[i].time = resolve.data.records[i].gmtCreate;
          resolve.data.records[i].username = resolve.data.records[i].singer;
        }
        this.newListAll = resolve.data.records;

        this.reNewList();
      });
    this.$api
      .sendOnlyRanking("quarter", { size: 20, page: 1, shelves: 1 })
      .then((resolve) => {
        for (let i = 0; i < resolve.data.data.length; i++) {
          resolve.data.data[i].play_mun = resolve.data.data[i].times;
          resolve.data.data[i].time = resolve.data.data[i].gmtCreate;
          resolve.data.data[i].username = resolve.data.data[i].singer;
        }
        this.hotListAll = resolve.data.data;
        this.reHotList();
      });
  },
};
</script>
<style lang="scss" scoped>
.hr {
  margin-top: 40px;
  height: 1px;
  background: #eee;
}
.row {
  display: grid;
  grid-template-columns: repeat(5, 20%);
}
.t {
  margin: 40px 0 20px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  > span {
    //display: inline-block;
    float: right;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
    //background: url("../../../assets/home/2.png");
    background-size: 100%;
    width: 71px;
    height: 20px;
    display: flex;
    align-items: center;
    img {
      width: 18px;
      margin-right: 10px;
    }
    > img:nth-child(1) {
      display: block;
    }
    > img:nth-child(2) {
      display: none;
    }
  }
  > span:hover {
    > img:nth-child(1) {
      display: none;
    }
    > img:nth-child(2) {
      display: block;
    }
    color: #c20c0cff;
    //background: url("../../../assets/home/1.png");
    background-size: 100%;
  }
}

@keyframes listAnima {
  0% {
    //transform:rotateY(180deg);
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    //transform:rotateY(360deg);
    opacity: 1;
  }
}

.list-anima {
  animation: listAnima 1s ease;
}
</style>
