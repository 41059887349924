<template>
  <div class="synopsis-container" @click="$emit('to')">
    <div class="image"><img :src="data.image" /></div>
    <div class="t">
      <el-tooltip :content="data.name" placement="top"
        ><div>{{ data.name }}</div></el-tooltip
      >
      <div>{{ data.singer }}</div>
    </div>
    <div class="time">{{ data.date | mixinRetainYMD }}</div>
    <div class="play" @click.stop="play(data)">
      <img src="../../assets/home/3.png" />
    </div>
  </div>
</template>
<script>
import { mixinRetainYMD } from "@/mixin/date";
export default {
  mixins: [mixinRetainYMD],
  props: {
    data: Object,
  },
  methods: {
    play(single) {
      single.play(this);
      // this.$emit('play')
    },
  },
};
</script>
<style scoped lang="scss">
.synopsis-container {
  width: 489px;
  height: 90px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f2f2f2ff;
    .play {
      opacity: 1;
    }
  }
  .image {
    width: 90px;
    height: 90px;
    display: flex;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .t {
    width: 0;
    flex-grow: 1;
    margin-left: 20px;
    > div {
      margin: 17px 0;
    }
    > div:nth-child(1) {
      font-size: 16px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    > div:nth-child(2) {
      font-size: 12px;
      color: #a0a2a6;
    }
  }
  .time {
    color: #a0a2a6;
    font-size: 12px;
  }
  .play {
    width: 16px;
    height: 16px;
    margin: 0 14px 0 41px;
    opacity: 0;
    display: flex;
    img {
      width: 100%;
    }
  }
}
</style>
