<template>
  <div class="ranking-container">
    <div class="left">
      <div class="h">音乐排行榜</div>
      <div
        v-for="(item, index) in rankingList"
        :key="index"
        class="left-item"
        :class="{ current: !id && item.l == l }"
        @click="to(1, item.l)"
      >
        {{ item.name }}
      </div>

      <div class="h">热门歌单</div>
      <div
        v-for="(item, index) in hotList"
        :key="index + 'hotList'"
        class="left-item"
        :class="{ current: item.id == id }"
        @click="to(2, item.id)"
      >
        {{ item.name }}
      </div>
    </div>

    <div class="right">
      <div class="right-h">
        {{ title }}
        <span
          ><img src="../../assets/time.png" />最近更新：{{
            new Date().getFullYear()
          }}-{{ new Date().getMonth() + 1 }}-{{ new Date().getDate() }}</span
        >
      </div>

      <div style="display: flex; margin-bottom: 36px">
        <com-button
          v-if="l && !id"
          style="margin-right: 16px"
          @click="playAll"
          :icon="require('../../assets/play.png')"
        >
          全部播放</com-button
        >
      </div>

      <table-img
        v-if="id"
        :total="10"
        :currentPage="currentPage"
        :pageSize="pageSize"
        @currentPageChange="pageChang"
        :list="list"
        :grid="{ column: 4, cell: '25%' }"
        v-loading="loading"
        to="singleDetail"
      ></table-img>

      <table-single
        v-else
        :list="list"
        :ranking="true"
        :total="10"
        :currentPage="currentPage"
        :pageSize="pageSize"
        @currentPageChange="pageChang"
        @select="selectListChange"
        v-loading="loading"
      ></table-single>
    </div>
  </div>
</template>
<script>
import tableSingle from "@/components/table/table-single";
import tableImg from "@/components/table/table-img";
import comButton from "@/components/com/com-button";
import { Single } from "@/class/music";
export default {
  components: {
    tableSingle,
    tableImg,
    comButton,
  },
  data() {
    return {
      rankingList: [
        {
          name: "周榜",
          l: 1,
        },
        {
          name: "月榜",
          l: 2,
        },
        {
          name: "季榜",
          l: 3,
        },
      ],
      hotList: [],
      scoreShow: false,
      list: [],
      selectList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
    };
  },
  computed: {
    l() {
      let l = this.$route.query.l;
      return l ? l : this.rankingList[0].l;
      //
    },
    id() {
      let id = this.$route.query.id;
      return id;
    },
    title() {
      let { id } = this;
      let res;
      res = id
        ? this.hotList.find((item) => item.id == this.id)
        : this.rankingList.find((item) => item.l == this.l);
      return res?.name;
    },
    // hotList(){
    //   return this.$store.state.com.headerList.slice(0,4)
    // },
    send() {
      let { i, id } = this;
      return { i, id };
    },
  },
  watch: {
    send: {
      immediate: true,
      handler(v) {
        this.currentPage = 1;
        this.getList(v);
      },
    },
    // "$store.state.com.headerList": {
    //   immediate: true,
    //   handler(list) {
    //     this.hotList = list.slice(0, 4);
    //   },
    // },
  },
  methods: {
    to(type, d) {
      type === 1 && this.$router.push("/home?type=2&l=" + d);
      type === 2 && this.$router.push("/home?type=2&id=" + d);
    },
    getList(v) {
      v.id ? this.sendGetSongsList() : this.sendGetList();
    },
    sendGetList() {
      this.loading = true;
      let type =
        (this.l == 1 && "week") ||
        (this.l == 2 && "month") ||
        (this.l == 3 && "quarter");
      this.$api
        .sendOnlyRanking(type, { size: this.pageSize, page: this.currentPage })
        .then((resolve) => {
          this.loading = false;
          this.total = resolve.data.total;
          this.list = resolve.data.data.map((item) => {
            let single = new Single(item);
            return single;
          });
        })
        .catch(() => (this.loading = false));
    },

    sendGetSongsList() {
      this.loading = true;
      //songsList
      this.$api
        .bySongsGetSingleList({
          listId: this.id,
          size: this.pageSize,
          current: this.currentPage,
        })
        .then((resolve) => {
          this.loading = false;
          this.total = resolve.data.total;
          this.list = resolve.data.records.map((item) => {
            return {
              play_mun: item.musicTimes,
              time: item.gmtCreate,
              username: item.singer,
              cover: item.musicCover,
              id: item.musicId,
              name: item.musicName,
            };
          });
        })
        .catch(() => (this.loading = false));
    },
    sendHotSongs() {
      this.$api
        .sendOnlySongsRanking("quarter", {
          size: 4,
          page: 1,
        })
        .then((resolve) => {
          this.hotList = resolve.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
            };
          });
          this.hotList.length<4 && this.sendHotSongsBackup()
        });
    },
    sendHotSongsBackup() {
      this.$api.songsList({ size: 4, current: 1 }).then((resolve) => {
        resolve.data.records.forEach((item) => {
          let index = this.hotList.findIndex((hot) => ~~hot.id === ~~item.id);
          if (index === -1 && this.hotList.length<4) {
            this.hotList.push({
              id: item.id,
              name: item.name,
              bg: item.cover,
            });
          }
        });
      });
    },
    pageChang(currentPage) {
      this.currentPage = currentPage;
      this.getList(this.l);
    },
    selectListChange(selection) {
      // console.log(selection,row)
      this.selectList = selection;
    },
    playAll() {
      this.$store.commit("ADD_PLAYLIST", this.list);
      // this.selectList[0]
      //   ? this.$store.commit("ADD_PLAYLIST", this.selectList)
      //   : this.$message({ type: "success", message: "请先选中要播放的歌曲" });
    },
  },
  mounted() {
    this.sendHotSongs();
  },
};
</script>
<style scoped lang="scss">
$titleHeight: 60px;
.ranking-container {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  .h {
    height: $titleHeight;
    line-height: $titleHeight;
    border-bottom: 1px solid #eeeeee;
    margin: 0 18px 10px;
    font-weight: 600;
  }
  .left {
    flex-shrink: 0;
    width: 200px;
    //height: 473px;
    padding-bottom: 10px;
    box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.1);
    margin-right: 101px;
    .left-item {
      height: 38px;
      line-height: 38px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      padding-left: 22px;
      cursor: pointer;
      &:hover {
        background: #c20c0cff;
        color: #ffffff;
      }
    }
    .left-item.current {
      color: #ffffff;
      background: #c20c0cff;
    }
  }
  .right {
    flex-grow: 1;
    .right-h {
      //height: $titleHeight;
      //line-height: $titleHeight;
      margin-bottom: 25px;
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #333333;
      display: flex;
      align-items: flex-end;

      span {
        font-size: 14px;
        color: #696b6f;
        display: flex;
        align-items: center;
      }
      img {
        width: 15px;
        margin: 0 10px 0 20px;
      }
    }
  }
}
</style>
