<template>
  <div>
    <com-tab
      t="全部歌曲"
      :buttons="['最新', '最热']"
      :currentIndex.sync="currentIndex"
    ></com-tab>
    <com-button
      tyle="allPlay"
      style="margin-bottom: 20px"
      :icon="require('../../assets/play.png')"
      @click="allPlay"
      >全部播放</com-button
    >
    <table-single
      v-loading="loading"
      :key="currentIndex"
      :list="list"
      :image="true"
      :type="true"
      :total="total > 50 ? 50 : total"
      :currentPage="currentPage"
      :pageSize="pageSize"
      @currentPageChange="pageChang"
    ></table-single>
  </div>
</template>
<script>
import tableSingle from "@/components/table/table-single";
import comTab from "@/components/com/com-tab";
import comButton from "@/components/com/com-button";
import { Single } from "@/class/music";
export default {
  data() {
    return {
      currentIndex: 0,
      list: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      hotAllList: [],
    };
  },
  watch: {
    currentIndex(v) {
      this.currentPage = 1;
      this.$router.push({ query: { ...this.$route.query, form: v } });
    },
    send: {
      immediate: true,
      handler(d) {
        let { form, currentIndex } = d;
        if (!Number.isNaN(form) && form !== currentIndex)
          this.currentIndex = ~~form;
        if (Number.isNaN(form) || form === currentIndex) this.sendGetList();
      },
    },
  },
  computed: {
    send() {
      let form = parseInt(this.$route.query.form);
      let { currentIndex } = this;
      return { form, currentIndex };
    },
  },
  components: {
    tableSingle,
    comTab,
    comButton,
  },
  methods: {
    sendGetList() {
      if (this.currentIndex === 0) {
        //获取最新
        this.loading = true;
        this.$api
          .singleList({
            size: this.pageSize,
            current: this.currentPage,
            shelves: 1,
          })
          .then((resolve) => {
            this.loading = false;
            this.total = resolve.data.total;
            this.list = resolve.data.records.map((item) => {
              item.name = item.musicName;
              item.type = item.musicTypeName;
              return new Single(item);
            });
          })
          .catch(() => (this.loading = false));
      } else {
        //获取最热
        if (!this.hotAllList[0]) {
          this.loading = true;
          this.$api
            .sendOnlyRanking("quarter", {
              size: 50,
              page: 1,
            })
            .then((resolve) => {
              this.loading = false;
              this.total = resolve.data.total;
              this.hotAllList = resolve.data.data.map((item) => {
                item.type = item.typeName;
                let single = new Single(item);
                return single;
              });
              console.log(this.hotAllList,(this.currentPage - 1) * this.pageSize)
              this.list = this.hotAllList.slice(
                (this.currentPage - 1) * this.pageSize,
                this.currentPage * this.pageSize
              );
              console.log(this.list)
            })
            .catch(() => (this.loading = false));
        } else {
          this.total = this.hotAllList.length
          this.list = this.hotAllList.slice(
            (this.currentPage - 1) * this.pageSize,
            this.currentPage * this.pageSize
          );
        }
      }
    },
    allPlay() {
      this.$store.commit("ADD_PLAYLIST", this.list);
    },
    pageChang(currentPage) {
      this.currentPage = currentPage;
      this.sendGetList();
    },
  },
};
</script>
