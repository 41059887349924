<template>
  <div class="songs-container">
    <com-tab
      t="全部歌单"
      :buttons="['最新', '最热']"
      :currentIndex.sync="currentIndex"
    ></com-tab>
    <table-img
      v-loading="loading"
      :total="total"
      :currentPage="currentPage"
      :pageSize="pageSize"
      @currentPageChange="pageChang"
      :list="list"
      :key="currentIndex"
    ></table-img>
  </div>
</template>
<script>
import tableImg from "@/components/table/table-img";
import comTab from "@/components/com/com-tab";
export default {
  data() {
    return {
      currentIndex: 0,
      list: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
    };
  },
  components: {
    comTab,
    tableImg,
  },
  watch: {
    currentIndex(v) {
      this.currentPage = 1;
      this.$router.push({ query: { ...this.$route.query, form: v } });
    },
    send: {
      immediate: true,
      handler(d) {
        let { form, currentIndex } = d;
        if (!Number.isNaN(form) && form !== currentIndex)
          this.currentIndex = form;
        if (Number.isNaN(form) || form === currentIndex) this.sendGetList();
      },
    },
  },
  computed: {
    send() {
      let form = parseInt(this.$route.query.form);
      let { currentIndex } = this;
      return { form, currentIndex };
    },
  },
  methods: {
    sendGetList() {
      this.loading = true;
      if (this.currentIndex === 0) {
        this.$api
          .songsList({ size: this.pageSize, current: this.currentPage })
          .then((resolve) => {
            this.loading = false;
            this.total = resolve.data.total;
            this.list = resolve.data.records.map((item) => {
              return {
                cover: item.cover,
                singer: item.singer,
                name: item.name,
                songs: item.musicTypeName,
                time: item.gmtCreate,
                id: item.id,
                play_mun: item.times,
                username: item.userName,
              };
            });
          })
          .catch(() => (this.loading = false));
      } else {
        this.$api
          .sendOnlySongsRanking("quarter", {
            size: this.pageSize,
            page: this.currentPage,
          })
          .then((resolve) => {
            this.loading = false;
            this.total = resolve.data.total;
            this.list = resolve.data.data.map((item) => {
              return {
                cover: item.cover,
                singer: item.singer,
                name: item.name,
                songs: item.musicTypeName,
                time: item.gmtCreate,
                id: item.id,
                play_mun: item.times,
                username: item.userName,
              };
            });
          })
          .catch(() => (this.loading = false));
      }
    },
    pageChang(currentPage) {
      this.currentPage = currentPage;
      this.sendGetList();
    },
  },
};
</script>
