<template>
  <div>
    <home-swiper
      style="margin-top: 20px"
      :list="banner"
      v-loading="loadings.bannerLoading"
    ></home-swiper>

    <com-title title="音乐推荐" class="mt">
      <div slot="rightBtn" class="more" @click="$router.push('/home?type=3')">
        更多
      </div>
      <recommend></recommend>
    </com-title>

    <com-title title="热门歌单" class="mt">
      <div
        slot="rightBtn"
        class="more"
        @click="$router.push('/home?type=4&form=1')"
      >
        更多
      </div>
      <div class="hot-songs">
        <div
          v-for="item in hots"
          :key="item.name"
          @click="$router.push(`/home?type=2&id=${item.id}`)"
          :style="{
            background: 'url(' + item.bg + ')',
            backgroundSize: '100% ',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }"
        >
          {{ item.name }}
        </div>
      </div>
    </com-title>

    <com-title title="音乐排行" class="mt">
      <div
        style="display: flex; justify-content: space-between; margin-top: 37px"
      >
        <home-order
          title="周榜"
          :list="zList"
          :bg="require('../../assets/home/bg3.png')"
        ></home-order>
        <home-order
          title="月榜"
          :list="yList"
          :bg="require('../../assets/home/bg1.png')"
        ></home-order>
        <home-order
          title="季榜"
          :list="jList"
          :bg="require('../../assets/home/bg2.png')"
        ></home-order>
      </div>
    </com-title>

    <com-title title="所有音乐" class="mt">
      <!--      <div slot="rightBtn" class="up" @click="reListLx">-->
      <!--        <img src="../../assets/home/2.png" />-->
      <!--        <img src="../../assets/home/1.png" />-->
      <!--        换一批-->
      <!--      </div>-->
      <div class="lx">
        <com-synopsis-small
          v-for="(item, index) in listLx"
          :key="index"
          :data="item"
          @to="$router.push(`/singleDetail/${item.id}`)"
        ></com-synopsis-small>
      </div>
      <com-page
        :total="allMusic.total"
        layout="prev, pager, next"
        :page-size="allMusic.pageSize"
        :currentPage="allMusic.currentPage"
        @change="pageChange"
      ></com-page>
    </com-title>
  </div>
</template>
<script>
import homeSwiper from "@/components/home/home-swiper.vue";
import comTitle from "@/components/com/com-title.vue";
import comPage from "@/components/com/com-page";
import comSynopsisSmall from "@/components/com/com-synopsis-small.vue";
import homeOrder from "@/components/home/home-order";
import recommend from "@/components/home/recommend/recommend-recommend";
import { Single } from "@/class/music";
export default {
  data() {
    return {
      hots: [
        // { id: "4", name: "经典", bg: require("../../assets/home/d1.png") },
        // { id: "5", name: "劲爆", bg: require("../../assets/home/d2.png") },
        // { id: "6", name: "车载", bg: require("../../assets/home/d3.png") },
        // { id: "7", name: "酒吧", bg: require("../../assets/home/d4.png") },
      ],
      zList: [],
      yList: [],
      jList: [],
      list: [],
      listLx: [],
      banner: [],
      loadings: {
        bannerLoading: false,
      },
      allMusic: {
        pageSize: 51,
        currentPage: 1,
        total: 0,
      },
    };
  },
  watch: {
    // "$store.state.com.headerList": {
    //   immediate: true,
    //   handler(list) {
    //     list &&
    //       this.hots.forEach((item, index) => {
    //         item.id = list[index].id;
    //         item.name = list[index].name;
    //       });
    //   },
    // },
  },
  components: {
    homeSwiper,
    comTitle,
    comPage,
    recommend,
    homeOrder,
    comSynopsisSmall,
  },
  methods: {
    reListLx() {
      // this.listLx = randomBackPart(this.list, 9);
      this.listLx = this.list;
    },
    sendHotSongs() {
      this.$api
        .sendOnlySongsRanking("quarter", {
          size: 4,
          page: 1,
        })
        .then((resolve) => {
          this.hots = resolve.data.data.map((item) => {
            return {
              id: item.id,
              name: item.name,
              bg: item.cover,
            };
          });
          this.hots.length < 4 && this.sendHotSongsBackup();
        });
    },
    sendHotSongsBackup() {
      this.$api.songsList({ size: 4, current: 1 }).then((resolve) => {
        resolve.data.records.forEach((item) => {
          let index = this.hots.findIndex((hot) => ~~hot.id === ~~item.id);
          if (index === -1 && this.hots.length<4) {
            this.hots.push({
              id: item.id,
              name: item.name,
              bg: item.cover,
            });
          }
        });
      });
    },
    sendGetList() {
      this.$api
        .singleList({
          size: this.allMusic.pageSize,
          current: this.allMusic.currentPage,
          shelves: 1,
        })
        .then((resolve) => {
          this.allMusic.total = resolve.data.total;
          this.list = resolve.data.records.map((item) => new Single(item));
          this.reListLx();
        });
    },

    //获取周月季排行榜
    sendRanking() {
      this.$api
        .sendRanking({
          weekSize: 10,
          monthSize: 10,
          quarterSize: 10,
          shelves: 1,
        })
        .then((resolve) => {
          this.yList = resolve.data.month.data.map((item) => new Single(item));
          this.jList = resolve.data.quarter.data.map(
            (item) => new Single(item)
          );
          this.zList = resolve.data.week.data.map((item) => new Single(item));
        });
    },

    //获取轮播图
    sendBanner() {
      this.loadings.bannerLoading = true;
      this.$api
        .sendBanner()
        .then((resolve) => {
          this.loadings.bannerLoading = false;
          // console.log(resolve);
          if (resolve.code === 200) {
            this.banner = resolve.data.records;
          }
        })
        .catch(() => (this.loadings.bannerLoading = false));
    },
    pageChange(currentPage) {
      this.allMusic.currentPage = currentPage;
      this.sendGetList();
    },
  },
  mounted() {
    this.sendGetList();
    this.sendBanner(); //获取轮播图
    this.sendRanking();
    this.sendHotSongs();
  },
};
</script>
<style scoped lang="scss">
.mt {
  margin-top: 67px;
}
.more {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #a0a2a6;
  cursor: pointer;
  &:hover {
    color: #c20c0c;
  }
}
.up {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  //width: 71px;
  height: 20px;
  display: flex;
  align-items: center;
  //background: url("../../assets/home/2.png");
  //background-size: 100%;
  cursor: pointer;
  img {
    width: 18px;
    margin-right: 10px;
  }
  img:nth-child(1) {
    display: block;
  }
  img:nth-child(2) {
    display: none;
  }
  &:hover {
    color: #c20c0c;
    img:nth-child(1) {
      display: none;
    }
    img:nth-child(2) {
      display: block;
    }
    //background: url("../../assets/home/1.png");
    //background-size: 100%;
  }
}
.hot-songs {
  //display: flex;
  //justify-content: space-between;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  margin-top: 37px;
  > div {
    cursor: pointer;
    width: 363px;
    height: 163px;
    line-height: 163px;
    //height: 141px;
    //line-height: 141px;
    text-align: center;
    font-size: 28px;
    background: #000;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
  }
}
.lx {
  display: flex;
  flex-wrap: wrap;
  //justify-content: space-between;
  margin-top: 38px;
  > div {
    width: 33.3%;
    margin-bottom: 20px;
  }
}
</style>
